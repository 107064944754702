<template>
    <div class="page">
        <headeBar title="可使用门店" left-arrow @click-left="appBack"/>
        <form class="search row-between-center" action="/">
            <van-search
                style="flex-grow: 1"
                v-model="venueNameLike"
                placeholder="搜索门店位置"
                @search="getMonthCardVenue"
                @cancel="getMonthCardVenue"
                @clear="getMonthCardVenue"
            />
            <p v-if="venueNameLike" @click="venueNameLike = '';getMonthCardVenue()">取消</p>
        </form>
        <div v-if="venueList.length" class="list">
            <div v-for="(item, index) in venueList" :key="index" class="item">
                <h3>{{item.venueName}}</h3>
                <p class="ellipsis">{{item.address}}</p>
            </div>
        </div>
        <div v-else class="list col-center-center empty">
            <img src="https://img.chaolu.com.cn/MINI/icon/empty_icon.png" alt="">
            <div>暂无符合的门店，换个词试试吧</div>
        </div>
    </div>
</template>

<script>
import {newAppBack} from "@/lib/appMethod";
import userMixin from "@/mixin/userMixin";
import headeBar from "@/components/app/headBar.vue";
export default {
    data() {
        return {
            venueList: [],
            venueNameLike: '',
        };
    },
    mixins: [userMixin],
    components: {
        headeBar,
    },
    async created() {
        await this.$getAllInfo()
        this.getMonthCardVenue()
    },
    mounted() {
    
    },
    methods: {
        getMonthCardVenue(){
            this.$axios.post(this.baseURLApp + '/user/equityCard/equityCardVenueSearch', {
                equityCardUserId: this.$route.query.userMembershipCardId,
                lat: this.local ? this.local.lat : '',
                lng: this.local ? this.local.lng : '',
                token: this.token,
                userId: this.userId,
                venueNameLike: this.venueNameLike
            }).then(res =>{
                this.venueList = res.data ?? []
            })
        },
        appBack: newAppBack,
    },
};
</script>

<style lang="less" scoped>
.page{
    background-color: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100vh;
}
.search{
    flex-shrink: 0;
    p{
        font-size: 28px;
        font-weight: bold;
        line-height: 28px;
        color: #242831;
        padding-right: 32px;
    }
}
.list{
    flex-grow: 1;
    overflow-y: auto;
    padding-bottom: calc(40px + env(safe-area-inset-bottom));
    .item{
        padding: 32px;
        h3{
            line-height: 30px;
            font-size: 30px;
            color: #242831;
        }
        p{
            margin-top: 24px;
            line-height: 22px;
            font-size: 22px;
            color: #6C727A;
        }
        & + .item{
            border-top: 1px solid #eee;
        }
    }
}
/deep/ .van-search{
    padding: 24px 32px;
    .van-field{
        border-radius: 8px;
    }
}
.empty{
    img{
        width: 320px;
        height: 320px;
    }
    div{
        margin-top: 48px;
        font-size: 24px;
        color: #6C727A;
        line-height: 36px;
    }
}
</style>
